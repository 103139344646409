import React, { Component } from "react";
import { Card, Button, Row, Col, Avatar, Table, Typography, Collapse, Icon } from "antd";
import storageUtils from "../../utils/storageUtils"
import { isBlank, isNotBlank, notify } from "../../utils/htmlUtils"
import Footer from "../../components/footer"
import { Login } from "./login/index";
import UseForm from "./useForm/index";
import './index.less'
import { getUseByAPI } from "../../api";

import png1 from "./img/1.png"
import png2 from "./img/2.png"
import png3 from "./img/3.png"
import listItem from "./img/listItem.png"


export default class UseManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            needLogin: false,
            useForm: false,
            useData: []
        };
    }

    //组件第一次渲染后调用，获取所有数据
    componentDidMount() {
        let phone = storageUtils.getPhone()
        if (isBlank(phone)) {
            this.setState({ needLogin: true })
        }
        this.getUseFunc()
    }

    getUseFunc = async () => {
        let phone = storageUtils.getPhone()
        if (isNotBlank(phone)) {
            let params = {
                phone: phone
            }
            await getUseByAPI(params).then((data) => {
                if (data.code == '200' && data.msg == 'ok') {
                    this.setState({ useData: data.data })
                }
            });
        }
    };

    useFunc = () => {
        let phone = storageUtils.getPhone()
        if (isBlank(phone)) {
            this.setState({ needLogin: true })
        } else {
            this.setState({ useForm: true })
        }
    }

    callBack = () => {
        this.setState({
            needLogin: false,
            useForm: false
        })
        this.getUseFunc()
    }

    render() {
        let { useData } = this.state;
        const columns = [
            {
                title: '名称',
                dataIndex: 'name',
                width: 120,
                align: 'right'
            },
            {
                title: '链接',
                dataIndex: 'url',
                align: 'left',
                width: 800,
                render(text) {
                    return (
                        <Typography.Paragraph copyable={true}>
                            {text}
                        </Typography.Paragraph>
                    )
                }
            },
        ]

        const demoData = [
            {
                "title": "CID配置任务示例1",
                "channel": "抖音-淘宝",
                "itemUrl": "https://item.taobao.com/item.htm?id=706819072874",
                "shopName": "NASACLOT旗舰店",
                "expireTime": "2023-04-29 15:38:11",
                "urlList": [
                    {
                        "name": "落地页链接",
                        "url": "https://uland.taobao.com/coupon/edetail?xxxxxxxx"
                    },
                    {
                        "name": "直达链接",
                        "url": "taobao://uland.taobao.com/coupon/edetail?xxxxxxxx"
                    },
                    {
                        "name": "展示监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx",
                    },
                    {
                        "name": "有效触点监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    },
                    {
                        "name": "视频播放监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    },
                    {
                        "name": "视频播完监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    },
                    {
                        "name": "视频有效播放监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    }
                ]
            },
            {
                "title": "CID配置任务示例2",
                "channel": "抖音-淘宝",
                "itemUrl": "https://item.taobao.com/item.htm?id=706819072874",
                "shopName": "NASACLOT旗舰店",
                "expireTime": "2023-04-29 15:37:57",
                "urlList": [
                    {
                        "name": "直达链接",
                        "url": "https://uland.taobao.com/coupon/edetail?xxxxxxxx"
                    },
                    {
                        "name": "deepLink链接",
                        "url": "taobao://uland.taobao.com/coupon/edetail?xxxxxxxx"
                    },
                    {
                        "name": "展示监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx",
                    },
                    {
                        "name": "有效触点监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    },
                    {
                        "name": "视频播放监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    },
                    {
                        "name": "视频播完监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    },
                    {
                        "name": "视频有效播放监测链接",
                        "url": "https://mkt.shuqiaozt.com/mktMonitor/xxxxxxxxx"
                    }
                ]
            }
        ]

        let cidUseList = isNotBlank(useData) ? useData : demoData;

        return (
            <div>
                <Login visible={this.state.needLogin} callback={this.callBack}></Login>
                <UseForm visible={this.state.useForm} phone={storageUtils.getPhone()} callback={this.callBack}></UseForm>
                <div><img width="50%" src={png1} onClick={() => { this.useFunc() }}></img><img width="50%" src={png2}></img></div>
                <div><img width="100%" src={png3} onClick={() => { this.useFunc() }}></img></div>
                <div className="cidList">
                    {
                        cidUseList.map((useItem) => {
                            return (
                                <div className="cidItem">
                                    <Card
                                        bordered={false}
                                        title={<div><Avatar src={listItem} />{useItem.title}</div>}
                                        headStyle={{ fontSize: "24px" }}
                                        extra={<Button type="primary" shape="round" onClick={() => { window.open("https://wzo89n4prg.feishu.cn/docx/QgPrdgz0moGZRExZYkRcNDuSnAb") }}>查看配置文档</Button>}>
                                        <Row style={{ backgroundColor: "#F4F9FF" }} gutter={24}>
                                            <Col span={6}>
                                                <span>投放链路:</span>
                                                <h3>{useItem.channel}</h3>
                                            </Col>
                                            <Col span={6}>
                                                <span>店铺名称:</span>
                                                <h3>{useItem.shopName}</h3>
                                            </Col>
                                            <Col span={12}>
                                                <span>商品链接:</span>
                                                <h6>{useItem.itemUrl}</h6>
                                            </Col>
                                        </Row>
                                        <Collapse
                                            bordered={false}
                                            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
                                            <Collapse.Panel header="配置链接" key="1">
                                                <Table showHeader={false} pagination={false} columns={columns} dataSource={useItem.urlList} />
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Card>
                                </div>
                            )
                        })
                    }
                </div>
                <div></div>
                <Footer />
            </div>
        )
    }
}
