/*
应用中所有接口请求模块
    |- 每个接口返回的都是promise对象
 */

import ajax from "./ajax"

const BASE = '/web'


export const getCodeByAPI = data => ajax(BASE + '/mobile/getCode',data,"POST")
export const loginByAPI = data => ajax(BASE + '/mobile/login',data,"POST")
export const bookingByAPI = data => ajax(BASE + '/mobile/booking',data,"POST")
export const saveUseByAPI = data => ajax(BASE + '/use/save',data,"POST")
export const getUseByAPI = data => ajax(BASE + '/use/get',data,"POST")