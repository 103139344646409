import React, { Component } from "react"
import { Menu, Modal, Popconfirm } from "antd"
import { withRouter } from 'react-router-dom'
import './index.less'
import storageUtils from "../../utils/storageUtils"
import { isBlank, isNotBlank } from "../../utils/htmlUtils"

import logo from "./logo.png"

const { confirm } = Modal

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }
    handleCharacterChange = (selectedCharacter) => {
        if (this.props.onCharacterChange) {
            this.props.onCharacterChange(selectedCharacter)
        }
    }

    showDrawer = () => {
        this.setState({
            visible: true
        })
    };

    render() {
        return (
            <div className="header">
                <div>
                    <img className="header-icon" width="350px" src={logo}></img>
                </div>
                <Menu
                    mode="horizontal"
                    className="header-menu"
                    selectedKeys={this.props.selectedCharacter}
                >
                    {
                        this.props.menuList.map(menu => (
                            <Menu.Item className="header-menu-item" key={menu.key} title={menu.title} onClick={() => this.handleCharacterChange(menu.key)}>
                                <span>{menu.title}</span>
                            </Menu.Item>
                        ))
                    }
                </Menu>
                {/* <div className="header-right">
                    {isNotBlank(storageUtils.getPhone()) ?
                        <Popconfirm placement="left" title={"确认切换手机吗"} onConfirm={() => storageUtils.delPhone()}
                            okText="确认" cancelText="取消">
                            <a>切换手机</a>
                        </Popconfirm> : null}
                </div> */}
                <div style={{ display: "none" }}>
                    什么是clickid
                    Click ID是广告和推广活动的唯一标识。它是一个由信息流投放系统自动生成的编号，用于跟踪用户的活动。帮助账户收集到极致精准的数据模型，搭建抖音、快手等往各电商平台引流的桥梁。简单来说就是通过Click ID数据监测到另外一个平台的广告数据，从而可以根据数据来优化广告计划，降低广告成本，提升ROl。
                </div>
                <div style={{ display: "none" }}>
                    比较火的clickid模式，抖音引流淘宝推广怎么做的
                    click id模式也称cid推广模式。是最近给予抖音研究出的一种新型的回流技术。商家可以通过抖音投放广告，将流量直接导入淘宝等电商店铺，而最终抖音广告账户获取到的购买的数据都是完全正确的。这种技术就叫做cid技术，使用这种技术的商家可以把广告的投产比提升到30％以上。
                </div>
                <div style={{ display: "none" }}>
                    电商Click id技术是什么
                    传统的跨平台渠道引流天猫、京东、拼多多，受限于平台数据闭环，品牌在引流时只能选择橙子建站跳转淘积木，深度优化时，只能选择店铺唤起与店铺停留，不能用成交数据指导投放优化，难以真正实现oCPX优化，大幅影响品牌投放规模与回报。
                </div>
                <div style={{ display: "none" }}>
                    ClickID的精准归因是怎样的
                    市场对 clickID 的效果反馈非常不错，对 ROI 的提升是基本可以肯定的
                    很多引流电商的广告主纷纷去尝试 clickID ，据了解目前 clickID 头部供应商该部分消耗已经有300万一天。有广告主反映目前该技术方案数据不稳定，供应商服务质量有待提高。
                </div>
            </div>

        )
    }
}

export default withRouter(Header)