/*
发送异步ajax请求函数模块
    |- 封装axios库
    |- 函数返回promise对象
 */

import axios from 'axios'


import { message } from "antd"
import Qs from 'qs';
import { isNotNull, log } from "../utils/htmlUtils";

// 响应拦截
axios.interceptors.response.use((response) => {
    return Promise.resolve(response.data)
}, (err) => {
    return Promise.reject(err)
})

export default function ajax(url, data = {}, type = 'GET', option) {
    for (const key in data) {
        log(key, data[key]);
        if (isNotNull(data[key]) && isNotNull(data[key].trim)) {
            data[key] = data[key].replace(/[\u200B-\u200D\uFEFF]/g, '').trim();
        }
    }
    return new Promise((resolve, reject) => {
        let promise
        // 1、执行异步ajax请求
        if (type === 'GET') {
            if (option === 'download') {
                promise = axios.get(url, {
                    params: data,
                    responseType: 'blob',
                    paramsSerializer: params => {
                        return Qs.stringify(params, { arrayFormat: 'repeat' })
                    }
                })
            } else if (option === 'JSON') {
                promise = axios.get(url, {
                    params: data,
                    headers: { "Content-Type": "application/json" },
                })
            } else {
                promise = axios.get(url, {
                    params: data,
                    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
                    paramsSerializer: params => {
                        return Qs.stringify(params, { arrayFormat: 'repeat' })
                    }
                })
            }
        } else {
            if (option === 'image') {
                promise = axios.post(url, data, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
            } else {
                promise = axios.post(url, data)
            }
        }

        // 2、如果成功了，调用resolve
        promise.then(response => {
            // if(!response.data.success){
            //     message.error("当前未登录")
            //     window.location = "/login"
            // }
            resolve(response)

            // 3、如果失败了，不调用reject，提示错误信息
        }).catch(error => {
            // message.error("请求失败:" + error.message);
            reject(error);
        })
    })

}
