import React, { Component } from "react"

import { Link } from "react-router-dom"
import { Layout, Icon, Menu, Tabs } from "antd"
import TweenOne from 'rc-tween-one';

import Header from "../../components/header"
import menuList from "../../config/menuConfig"
import './index.less'


import Cid from "../cid";


const { Footer, Content, Sider } = Layout
let createHistory = require('history').createBrowserHistory
createHistory()
const TabPane = Tabs.TabPane;


export default class Web extends Component {
    constructor(props) {
        super(props);
        const selectedKey = ""
        this.state = {
            collapsed: false,
            activeKey: '',//激活tabPane的key
            panes: [],//打开的pane
            selectedKey,//当前选中菜单的key
            selectedCharacter: '',
        };
    }
    /**
     * 组件已经挂载
     */
    componentDidMount() {
        this.handleCharacterChange()
    }


    handleCharacterChange = (selectedCharacter) => {
        console.log("selectedCharacter",selectedCharacter)
        this.setState({ selectedCharacter })
    }

    render() {
        return (
            <Layout style={{ height: '100%' }}>
                <Header menuList={menuList}
                    selectedCharacter={this.state.selectedCharacter}
                    onCharacterChange={this.handleCharacterChange} />
                <Content style={{ paddingBottom: '75px' }}>
                    {
                        this.state.selectedCharacter ?
                        menuList.map((menu) => {
                            if (menu.key === this.state.selectedCharacter) {
                                let Component = menu.component
                                return (
                                    <Component onCharacterChange={this.handleCharacterChange}/>
                                )
                            }
                        }) : menuList.map((menu) => {
                            if (menu.key === "/cid") {
                                let Component = menu.component
                                return (
                                    <Component onCharacterChange={this.handleCharacterChange}/>
                                )
                            }
                        })
                    }
                </Content>
            </Layout>
        )
    }
}