import React, { Component } from "react";
import { Card, Col, Row } from "antd";
import Footer from "../../components/footer"
import { Login } from "./login/index";
import storageUtils from "../../utils/storageUtils"
import { isBlank, isNotBlank, notify } from "../../utils/htmlUtils"

import png1_1 from "./img/1.1.png"
import png1_2 from "./img/1.2.png"
import png2 from "./img/2.png"
import png3 from "./img/3.png"
import png4 from "./img/4.png"
import png5 from "./img/5.png"
import f from "./img/f.png"
import a from "./img/a.png"
import s from "./img/s.png"
import t from "./img/t.png"

import { bookingByAPI } from "../../api";


export default class CidManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            needLogin: false
        };
    }

    handleCharacterChange = () => {
        if (this.props.onCharacterChange) {
            this.props.onCharacterChange("/use")
        }
    }

    //组件第一次渲染后调用，获取所有数据
    componentDidMount() {
    }

    onBookingClick = async () =>{
        let phone = storageUtils.getPhone()
        if (isNotBlank(phone)) {
            let params = {
                phone: phone
            }
            await bookingByAPI(params).then((data) => {
                if (data.code == '200' && data.msg == 'ok') {
                    notify("success","预约成功通知",data.data)
                }
            });
        }else {
            this.setState({
                needLogin: true
            })
        }
    }

    callBack = () => {
        this.setState({
            needLogin: false
        })
    }

    render() {
        return (
            <div>
                <Login visible={this.state.needLogin} callback={this.callBack}></Login>
                <div><img width="50%" src={png1_1} onClick={() => this.handleCharacterChange()}></img><img width="50%" src={png1_2}></img></div>
                <div><img width="100%" src={png2}></img></div>
                <div>
                    <Row gutter={[24, 16]}>
                        <Col span={6}><Card bordered={false} hoverable><img width="100%" src={f}></img></Card></Col>
                        <Col span={6}><Card bordered={false} hoverable><img width="100%" src={a}></img></Card></Col>
                        <Col span={6}><Card bordered={false} hoverable><img width="100%" src={s}></img></Card></Col>
                        <Col span={6}><Card bordered={false} hoverable><img width="100%" src={t}></img></Card></Col>
                    </Row>
                </div>
                <div><img width="100%" src={png3}></img></div>
                <div><img width="50%" src={png4} onClick={() => this.onBookingClick()}></img><img width="50%" src={png5}></img></div>
                <Footer/>
            </div>
        )
    }
}
