import React from 'react'
import { HashRouter, Route, Switch } from "react-router-dom"
import {Helmet} from "react-helmet"
import Web from './pages/web'

import './App.css'


function App() {
  return (
    <HashRouter>
      <Helmet>
        <meta name="keywords" content="clickid,cid,click-id,抖音CID,淘宝CID,CID服务商,clickid投放,cid服务商,cid数据回传,数桥智投,引流电商,巨量引擎,淘宝cid,广告投放" />
        <meta name="description" content="clickid技术，数据回传，提升ROI，链路稳定，抖音、快手、微博等媒体引流至淘宝天猫、京东、拼多多，行业领先技术，安全可靠" />
      </Helmet>
      <Switch>
        <Route path="/" component={Web} />
      </Switch>
    </HashRouter>
  );
}

export default App;
