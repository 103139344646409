import React from "react";
import { Modal, Form, Input, Radio, message } from 'antd';
import './index.less'
import { getCodeByAPI, loginByAPI, saveUseByAPI } from "../../../api";
import { isBlank, isNotBlank, notify } from "../../../utils/htmlUtils"


class UseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            phone: null
        };
    }

    //这里很重要，接受父组件的命令
    componentWillReceiveProps(props) {
        this.setState({
            visible: props.visible,
            phone: props.phone,
        })
    }

    handleOk = (event) => {
        // // 阻止事件的默认行为
        event.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            // 检验通过
            if (!err) {
                values.phone = this.state.phone
                this.saveUseFunc(values)
            }
        });
    };


    handleCancel = () => {
        this.setState({
            visible: false,
            phone: null,
        }, this.props.callback());
        this.props.form.resetFields();
    };

    saveUseFunc = async (params) => {
        await saveUseByAPI(params).then((data) => {
            if ((typeof data.code !== 'undefined' && data.code == '200') || (typeof data.msg !== 'undefined' && data.msg == 'ok')) {
                notify("success","成功",data.data);
                this.setState({
                    visible: false,
                    phone: null,
                }, this.handleCancel());
            }else {
                message.error(data.msg);
            }
        });
    };

    render() {

        const form = this.props.form
        const { getFieldDecorator } = form

        let { visible} = this.props;

        return (
            <Modal
                title={"免费使用"}
                visible={visible}
                width={600}
                okText={"提交"}
                cancelText={"取消"}
                centered={true}
                onOk={this.handleOk}
                onCancel={this.handleCancel}>
                <Form layout="vertical" ref={this.formRef}>
                    <Form.Item rules={[{ required: true, message: '该输入项为必输项！' }]} name='channel' label='投放媒体'>
                        {getFieldDecorator('channel', {
                            rules: [{ required: true, message: '该项为必选项！' }]
                        })(
                            <Radio.Group>
                                <Radio value='dy'>抖音</Radio>
                                <Radio value='ks' disabled={true}>快手</Radio>
                                <Radio value='aqy' disabled={true}>爱奇艺</Radio>
                                <Radio value='bili' disabled={true}>B站</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '该输入项为必输项！' }]} name='mall' label='电商平台'>
                        {getFieldDecorator('mall', {
                            rules: [{ required: true, message: '该项为必选项！' }]
                        })(
                            <Radio.Group>
                                <Radio value='tb'>淘宝</Radio>
                                <Radio value='jd' disabled={true}>京东</Radio>
                                <Radio value='pdd' disabled={true}>拼多多</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '该输入项为必输项！' }]} name='pageType' label='跳转方式'>
                        {getFieldDecorator('pageType', {
                            rules: [{ required: true, message: '该项为必选项！' }]
                        })(
                            <Radio.Group>
                                <Radio value='goodsOrderJump'>一跳</Radio>
                                <Radio value='goodsOrder' disabled={true}>二跳</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '该输入项为必输项！' }]} name='jumpType' label='跳转页面'>
                        {getFieldDecorator('jumpType', {
                            rules: [{ required: true, message: '该项为必选项！' }]
                        })(
                            <Radio.Group>
                                <Radio value='1'>详情页</Radio>
                                <Radio value='2' disabled={true}>种草页</Radio>
                                <Radio value='3' disabled={true}>淘积木</Radio>
                                <Radio value='4' disabled={true}>活动页</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '该输入项为必输项！' }]} name='shopName' label='店铺名称'>
                        {getFieldDecorator('shopName', {
                            rules: [{ required: true, message: '该输入项为必输项！' }]
                        })(
                            <Input placeholder="请填写准确的店铺名称，否则影响回传"/>
                        )}
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '该输入项为必输项！' }]} name='itemUrl' label='商品链接'>
                        {getFieldDecorator('itemUrl', {
                            rules: [{ required: true, message: '该输入项为必输项！' }]
                        })(
                            <Input.TextArea/>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const WarpUseForm = Form.create()(UseForm)
export default WarpUseForm