import React from "react";
import {Button, Icon, Input, message, Popover, Typography, notification} from "antd";
const { Paragraph } = Typography;

export const isNull = (obj, or) => {
    if (or === undefined) {
        return obj === undefined || obj === null;
    }else {
        return obj === undefined || obj === null? or: obj;
    }
};
export const isNotNull = obj => !isNull(obj);
export const isEmpty = list => list === undefined || list === null || list.length === 0;
export const isNotEmpty = list =>  !isEmpty(list);
export const isEmptyObject = obj => isNull(obj) || Object.keys(obj).length === 0
export const isNotEmptyObject = obj => !isEmptyObject(obj)
export const isBlank = (string, or) => or === undefined
    ? string === undefined || string === null || string.length === 0
    : string === undefined || string === null || string.length === 0? or: string;
export const isNotBlank = (string, or) => or === undefined? !isBlank(string): !isBlank(string)? or: string



let searchInput;
export const _getColumnSearchProps = filteredInfo => ({
    filteredValue: filteredInfo || null,
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters}) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                    searchInput = node
                }}
                placeholder={`Search`}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
                onPressEnter={() => {confirm()}}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
                type="primary"
                onClick={() => {confirm()}}
                icon="search"
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                OK
            </Button>
            <Button
                onClick={() => {clearFilters()}}
                size="small"
                style={{ width: 90 }}
            >
                Reset
            </Button>
        </div>
    ),
    filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select())
        }
    },
})
export const getColumnSearchProps = (filteredInfo, key) => _getColumnSearchProps(filteredInfo[key])

export const _getColumnChooseProps = (filteredInfo, map, isFilter = true) => ({
    filterMultiple: false,
    filteredValue: filteredInfo || null,//根据filteredInfo决定筛选参数
    filters: isFilter? map: undefined,
    render: key => {
        if (isEmpty(map) || isNull(key)) {
            return '';
        }
        for (const obj of map) {
            if (key !== undefined && obj.value.toString() === key.toString()) {
                return (obj.text);
            }
        }
    }
})
export const getColumnChooseProps = (filteredInfo, key, map, isFilter) => _getColumnChooseProps(filteredInfo[key], map, isFilter)

export const getColumnOrderProps = (sorter, key) => ({
    sorter: true,
    sortOrder: sorter.field === key? (sorter.order || null) : null,
})

export function getParagraph(text, smallText) {
    return (// copyable={typeof text === 'string' && isNotBlank(text)}
        <Popover placement="right" content={text}>
            <Paragraph ellipsis style={{marginBottom: "0em"}}>{smallText || text}</Paragraph>
        </Popover>
    )
}

/**
 * 表格文字排版1 
 * @param {*} text 
 * @param {*} popover  是否需要展示全部
 * @param {*} ellipsis 是否需要缩略
 * @param {*} copyable 是否需要复制
 */
export function getParagraphNew(text,popover,ellipsis,copyable) {
    if(popover){
        return (
            <Popover placement="right" content={text}>
                <Paragraph ellipsis={ellipsis} copyable={copyable} style={{marginBottom: "0em"}}>{text}</Paragraph>
            </Popover>
        )
    }else{
        return (
            <Paragraph ellipsis={ellipsis} copyable={copyable} style={{marginBottom: "0em"}}>{text}</Paragraph>
        )
    }
}


//注意，此工具会会渲染每个条件的全部元素，注意性能和空值处理
const runFunction = func => typeof func === "function"? func(): func;
export function If(boolean) {
    return new IfClass(boolean);
}

export class IfClass {
    constructor(boolean) {
        this.boolean = boolean;
        this.result = null;
    }

    if (boolean) {
        this.boolean = boolean;
        return this;
    }

    then (result) {
        if (this.boolean && this.result == null) {
            this.result = result;
        }
        return this;
    }

    elseIf (boolean) {
        this.boolean = boolean;
        return this;
    }

    else (result) {
        if (this.result != null) {
            return runFunction(this.result);
        }else {
            return runFunction(result);
        }
    }

    endIf () {
        return runFunction(this.result);
    }
}

export const For = object => {
    if (Array.isArray(object)) {
        return new ForArray(object);
    }
    if (typeof object === 'object') {
        return new ForObject(object);
    }
    return new ForArray([]);
}

class ForArray {
    constructor(list) {
        this.list = list;
    }
    then(mapFun) {
        return this.list.map(mapFun)
    }
    if(filterFun) {
        this.list = this.list.filter(filterFun)
        return this;
    }
    flatThen(mapFun) {
        return this.list.flatMap(mapFun);
    }
}

class ForObject {
    constructor(object) {
        this.object = object;
    }
    then(mapFun) {
        const tmp = {};
        for (const key of Object.keys(this.object)) {
            tmp[key] = mapFun(key, this.object[key]);
        }
        return tmp;
    }
    if(filterFun) {
        const tmp = {};
        for (const key of Object.keys(this.object)) {
            if (filterFun(key, this.object[key])) {
                tmp[key] = this.object[key];
            }
        }
        this.object = tmp;
        return this;
    }
    every(fun) {
        let answer = true
        for (const key of Object.keys(this.object)) {
            answer &= fun(key, this.object[key]);
        }
        return answer;
    }
}

export const emptyFunc = () => {}
export const selfFunc = a => a

export const compose = (firstFun = selfFunc, secondFun = selfFunc) => {
    return (...props) => secondFun(firstFun(...props));
}

function currying(fn) {
    return function _currying(...args) {
        if (args.length >= fn.length) {
            return fn(...args)
        }
        return function (...args2) {
            return _currying(...args, ...args2)
        }
    }
}

export const clone = (obj, key, value) => {
    const otherProperty = isNull(key)? {}: defineProperty({}, key, value);
    return Object.assign({}, obj, otherProperty);
}

export const defineProperty = (obj, key, value) => {
    return Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        value
    })
}

export const toLine = (name) => {
    return name.replace(/([A-Z])/g,"_$1").toLowerCase();
}

export const splitToList = (key, text) => {
    if (isNotNull(text)) {
        return text.split(key).map(text => <p key={text} style={{marginBottom: "0em"}}>{text}</p>);
    }
}

export const splitToListRender = currying(splitToList)

export const prefixRender = currying((unit, text) => {
    if (isNotNull(text)) {
        return unit + text
    }
})

export const suffixRender = currying((unit, text) => {
    if (isNotNull(text)) {
        return text + unit;
    }
})

export const checkResp = data => {
    if (isNull(data)) {
        data = {};
    }
    if (isNull(data.success)) {
        data.success = true;
    }
    if(!data.success) {
        message.error(isBlank(data.message, '失败'), 7);
        return data;
    } else {
        return data;
    }
}

export const defaultRowKey = (record, index) => index;

export const defaultScroll = {x:1,y: true}

export const isDebug = process.env.NODE_ENV === "development";
export const log = (...params) => {
    if (isDebug) {
        console.log("[debug]", ...params)
    }
}

export const copyableRender = (value) => (<Paragraph copyable style={{marginBottom: 0}}>{value}</Paragraph>)


export const notify = (type,title,msg) => {
    notification[type]({
      duration: 2,
      message: title,
      description: msg
    });
  };