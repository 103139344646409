import Cid from "../pages/cid";
import Use from "../pages/use";



const menuList = [
    {
        key: "/cid", title: "CID/clickId",  component: Cid,
    },
    // {
    //     key: "/wechat", title: "微信导流", component: Cid,
    // },
    {
        key: "/use", title: "免费使用", component: Use,
    },
]

export default menuList
