import React from "react";
import { Modal, Button, message } from 'antd';
import './index.less'
import storageUtils from "../../../utils/storageUtils"
import { getCodeByAPI, loginByAPI } from "../../../api";
import { isBlank, isNotBlank, notify } from "../../../utils/htmlUtils"

const regMobile = /0?(12|13|14|15|16|17|18|19)[0-9]{9}/
const regCode = /0?[0-9]{6}/

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            company: null,
            phone: null,
            code: null,
            codeText: '获取验证码',
            codeDisable: true,
        };
    }

    //这里很重要，接受父组件的命令
    componentWillReceiveProps(props) {
        this.setState({
            visible: props.visible,
        })
    }

    onCompany = (company) => {
        this.setState({
            company: company
        })
    }

    onPhone = (phone) => {
        if (regMobile.test(phone))
            this.setState({
                phone: phone,
                codeDisable: false
            })
    }

    onCode = (code) => {
        if (regCode.test(code))
            this.setState({
                code: code
            })
    }

    getCodeFunc = async () => {
        let params = {
            phone: this.state.phone,
            code: this.state.code
        }
        await getCodeByAPI(params).then((data) => {
            if (data.code == '200' && data.msg == 'ok') {
                notify("success","验证码获取成功",data.data)
            } else {
                message.error(data.msg);
            }
        });
        let time = 60;
        let _that = this;
        let timer = setInterval(function () {
            time--;
            _that.setState({
                codeText: time + 's后获取',
                codeDisable: true,
            })
            if (time <= 1) {
                clearInterval(timer)
                _that.setState({
                    codeText: '重新获取',
                    codeDisable: false,
                })
            }
        }, 1000)
    };

    loginFunc = async () => {
        let params = {
            phone: this.state.phone,
            code: this.state.code,
            company: this.state.company,
            type: 'login'
        }
        await loginByAPI(params).then((data) => {
            if (data.code == '200' && data.msg == 'ok') {
                storageUtils.savePhone(data.data)
                notify("success","验证成功","您已验证成功，点击免费使用开始体验吧！")
                this.handleCancel()
            } else {
                message.error(data.msg);
            }
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            phone: null,
            code: null,
            codeText: '获取验证码',
            codeDisable: true,

        }, this.props.callback());
    };

    render() {
        let { visible, codeText, codeDisable } = this.state;
        return (
            <Modal
                title={"手机号验证"}
                visible={visible}
                width={380}
                centered={true}
                footer={null}
                className="modal"
                onCancel={this.handleCancel}>
                <div>
                    <input className="company" placeholder={"输入公司名称"} onChange={e => { this.onCompany(e.target.value) }} />
                </div>
                <div>
                    <input className="phone" placeholder={"输入手机号"} onChange={e => { this.onPhone(e.target.value) }} />
                </div>
                <div>
                    <input className="code" placeholder={"输入验证码"} onChange={e => { this.onCode(e.target.value) }} />｜<button className="button" disabled={codeDisable} onClick={this.getCodeFunc}>{codeText}</button>
                </div>
                <div>
                    <Button className="submit" type="primary" shape="round" onClick={this.loginFunc}>免费使用</Button>
                </div>
            </Modal>
        );
    }
}
