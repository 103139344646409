import React, { Component } from "react"

import './index.less'

export default class Footer extends Component {

    render() {

        return (
            <div className="footer">
                杭州飞鸥网络技术有限公司 版权所有 ©2023 SHUQIAOZT.COM  <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022031788号</a> | 增值电信业务经营许可证 浙B2-20230101
            </div>

        )
    }
}