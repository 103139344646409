
// 使用原生的 localStorage 有些浏览器可能不支持，所以使用store库，支持所有常见浏览器，且代码更简洁
import store from 'store'

const PHONE_KEY = "current_phone"

export default {
    // 保存user
    savePhone(phone) {
        let data = {
            phone: phone,
            start: new Date().getTime(),
            expires: 1000 * 60 * 60 * 24
        }
        store.set(PHONE_KEY, data)
    },
    // 读取user
    getPhone() {
        let data = store.get(PHONE_KEY);
        // if(data){
        //     let now = new Date().getTime();
        //     if(now - data.start > data.expires){
        //         store.remove(PHONE_KEY);
        //         return null;
        //     }else{
        //         return data.phone
        //     }
        // }else {
        //     return null;
        // }
        if (data) {
            return data.phone
        } else {
            return null;
        }
    },

    // 删除user
    delPhone() {
        store.remove(PHONE_KEY)
    }
}